:root {
    --cor-body: #fcfbf7;
    --cor-principal: #19385d;
    --cor-primaria: #333333;
    --cor-text-secundario:  #808080 !important;
    --cor-border-secundario:  #8080808a !important;
    --cor-box-shadow: rgba(0,0,0,0.15);
    --cor-horario-calendario: #bdbdbd;
    --cor-border-calendario: #D7D8DA;
    


    --cor-borderBottom-sidebar: rgb(145, 245, 203);
    --cor-text-verde: rgb(25, 56, 93, 1);
    
    
    --cor-nav-hover: #fff;
    --cor-text-menu: #fff;
    --cor-border-radio:rgb(134,134,134);
    --cor-border-bottom-form:#e2dddd;
    
    
    --cor-principal-hover: rgba(101, 220, 169, 0.7);
    --cor-menu-icons : rgba(70, 69, 69,0.8);
    --cor-dia-selecionado-calendario: #19385d;
    --cor-range-calendario: #a8d89a33;
    --cor-expander-tabela:rgba(146, 158, 146, 0.88);
    --cor-marcador-gps: #4346f0;
    --cor-marcador-visita-negativada: #FFA500;
    --cor-marcador-visita-inicial: #3CB371;
    --cor-marcador-visita-final: #9ACD32;
    --cor-marcador-vendas: #B3EE3A;
    --cor-marcador-vendas-final: #6E8B3D;
    --cor-marcador-cliente: rgb(231, 216, 1);
    --cor-filtro-consulta: #19385d;
    --cor-button-danger: #dc3545;
    --cor-qtd-itens-footer-danger: rgba(247, 57, 57);
    --cor-qtd-itens-danger: rgba(247, 57, 57, 0.7);
    --cor-qtd-itens-warning: rgba(249, 134, 56, 0.7);
    --cor-qtd-itens-ok: rgba(135, 204, 140, 0.7);
    --cor-disabled:#c3c3c3;
}