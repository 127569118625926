.h-100vh,
html,
body,
#root {
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
}

.App-header {
    background: #f7f7f7
}

#root {
    overflow-x: hidden;
}

.principal {
    margin-left: 80px;
    min-height: 100vh !important;
    transition: transform 0.5s;
}

.principal.active {
    transform: translateX(160px);
}

.hidden {
    display: none !important
}

#draggable {
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
}

.gallery-control:focus {
    outline: 0;
}

/***************Login*******************/

.transparent {
    background-color: transparent !important
}

.div-login {
    padding: 10% !important;
    background: url(../images/Logintelalogin.png);
    background-repeat: no-repeat;
    background-color: var(--cor-principal);
    background-size: 40% 100%;
    font-weight: bolder;
    color: white;
}

.div-form-login {
    max-width: 250px;
    color: white;
    font-weight: 700;
    position: relative;
    background-color: var(--cor-principal);
}

.div-form-login .form-control.is-invalid {
    padding: .375rem .75rem !important;
}

.div-form-login button {
    margin: 30px auto;
    display: block !important;
    float: unset !important;
    height: 2.5rem;
    background-color: white !important;
    color: var(--cor-text-verde) !important;
    border-radius: 60px;
    font-weight: bolder;
}

.div-form-login a {
    text-align: center;
    color: var(--cor-text-white);
    display: block;
    text-decoration-line: underline;
    color: white !important;
}

.div-form-login .texto-explicativo-superior {
    text-align: center;
    color: var(--cor-text-white);
}

.div-endereco {
    text-align: center;
    font-weight: 400;
    padding-right: 30px !important;
    padding-bottom: 25px;
    margin-top: auto;
}

.div-endereco p {
    margin: 0;
}

.div-text-middle {
    margin: auto !important;
    text-align: center;
}

/*******************GRAFICO************/

.grafico-card {
    height: '100%';
}

.recharts-legend-item-text {
    font-size: 0.57em;
    font-weight: bold;
}

.recharts-legend-wrapper {
    right: 0 !important;
}

.recharts-legend-item {
    height: 14px;
}

.recharts-cartesian-axis-tick-value {
    font-size: 0.65em;
}

.grafico-card::before {
    content: attr(data-title);
    font-size: x-large;
    display: block;
    font-weight: bold;
    border-bottom: 1.5px solid var(--cor-principal);
    padding: 5px 0 5px 14px;
    color: var(--cor-text-secundario);
    font-size: 1.2em;
    font-weight: 430;
    letter-spacing: 0.025em;
    line-height: 36px;
    height: 50px;
}

.grid-layout {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(40%, 49.4%));
    grid-gap: 10px;
    grid-auto-rows: minmax(221px, auto);
    grid-auto-flow: dense;
    padding: 10px;
}

/***************SideBar****************/

input,
div,
label,
span {
    outline: none;
}

.texto-secundario,
.text-secondary,
.info-calendario-padrao {
    color: var(--cor-text-secundario) !important;
}

.sidebar {
    width: 80px;
    position: fixed !important;
    background-color: var(--cor-principal);
    min-height: 100vh !important;
    display: block;
    z-index: 1030;
    overflow-y: auto;
    transition: width 0.5s;
    box-shadow: 0 1px 4px 0 var(--cor-box-shadow) !important;
}

.sidebar.active {
    width: 240px;
    font-weight: 400;
}

/* .sidebar.active a svg {
    margin-right: 10px;
} */

.modal.show .modal-dialog {
    transform: inherit !important;
}

.modal.fade .modal-dialog {
    transform: inherit !important;
}

.modal-dialog {
    margin: 1.75rem auto !important;
}

.header-class {
    background: white;
    border: none;
}

table {
    margin-top: 8px
}

.no-padding {
    padding: 0 !important;
}

.modal-vw-85 {
    width: 85vw !important;
    max-width: 100% !important;
}

.no-padding-left {
    padding-left: 0 !important
}

.padding-left {
    padding-left: 8px !important
}

.padding-top {
    padding-top: 8px !important
}

.sidebar .nav a {
    text-decoration: none !important;
    color: var(--cor-text-menu);
    display: block;
    transition: .3s background-color;
    padding: 0.5rem 1.5rem;
    margin: 0px;
    /* border-bottom-color: var(--cor-borderBottom-sidebar); */
    /* border-left: 4px solid transparent; */
    fill: var(--cor-text-menu);
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.8rem;
}

.sidebar .nav a>span {
    display: inline-flex;
    justify-content: space-between;
    position: absolute;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s, visibility .3s step-end;
}

.sidebar .nav a>span.active {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s, visibility .5s;
}

.sidebar .nav a span>svg {
    margin-top: 0.23rem;
    transition: transform .5s;
}

.sidebar .nav a>span>svg.active {
    transform: rotate(180deg);
}

.submenu {
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    background-color: var(--cor-nav-hover) !important;
}

.submenu.nav a {
    color: var(--cor-text-secundario) !important;
    font-size: 0.7rem;
    padding: .5rem .0rem;
    font-weight: bold;
    line-height: normal;
    /* font-style: normal; */
    /* line-height: 25px; */
}

/* .sidebar .nav a.active .left-arrow {
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 10px solid var(--cor-body);
    float: right;
} */

.sidebar .nav a:hover,
.sidebar .nav a.active {
    border-left: 0px;
    border-radius: 0px !important;
    background-color: var(--cor-nav-hover) !important;
    color: var(--cor-principal) !important;
    fill: var(--cor-principal) !important;
}

.sidebar .nav a.active {
    box-shadow: 0px 0px 4px 0px var(--cor-box-shadow) !important;
}

.submenu.nav a.active {
    box-shadow: unset !important;
}

.submenu.nav a:hover,
.submenu.nav a.active {
    background-color: var(--cor-nav-hover) !important;
    color: var(--cor-principal) !important;
    /* box-shadow: inset 0px 0px 4px 0px var(--cor-box-shadow) !important; */
    fill: var(--cor-principal) !important;
}

.buttongroup-sidebar {
    justify-content: space-evenly !important;
    display: flex !important;
    padding-bottom: 0.5rem;
}

.logoSB {
    text-align: center;
    padding: 1.5rem 0.4rem 0.5rem 0.4rem;
    font-size: 25px !important;
    color: var(--cor-text-menu) !important;
    font-weight: bold !important;
}

.icon-menusidebar {
    float: right;
    cursor: pointer;
}

/*************FORMS**********************/

.input-invalid {
    border: 1px solid red !important;
    border-radius: .7rem !important;
    border-radius: 65px;
}

.form-control.is-invalid {
    border-color: #dc3545 !important;
    background-image: none !important;
    border-radius: 65px;
}

.form-control {
    border-radius: 0.7rem;
    height: calc(1.5em + .75rem);
    text-overflow: ellipsis !important;
}

.form-control-view {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 0.7rem;
    height: calc(1.5em + 1.75rem);
    text-overflow: ellipsis !important;
}

.tiulo-modal {
    font: bold !important;
}

/* .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    border-radius: .25rem !important;
    border: 1px solid #ced4da !important;
} */

/* textarea.form-control {
    border-radius: .25rem !important;
    border: 1px solid var(--cor-border-bottom-form) !important;
}

.form-col {
    
    margin: 0 0 10px 0;
} */

/* .form-control:focus {
    color: #495057;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--cor-principal)
} */

.form-group-feedback-input {
    width: 100%;
    margin-bottom: 0 !important;
    margin-left: 8px;
    border-radius: 65px;
}

.invalid-feedback {
    margin: 0px !important;
    border-radius: 65px;
}

.DayPickerInput {
    width: 100%;
}

.select,
.inline-block {
    display: inline-block;
}

.input-buscar {
    width: 100px !important
}

.select-form {
    width: 100%
}

.label-select {
    padding: 8px 0 0 0;
}

.label {
    flex: 0 0 auto
}

/*--------------Data------------------*/

.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: var(--cor-range-calendario) !important;
    color: var(--cor-dia-selecionado);
}

.DayPicker-Day {
    border-radius: 0 !important;
}

.DayPicker-Day--start:not(.DayPicker-Day--outside) {
    background: var(--cor-dia-selecionado-calendario) !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.DayPicker-Day--end:not(.DayPicker-Day--outside) {
    background: var(--cor-dia-selecionado-calendario) !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

/***************RADIO***************/

.custom-radio .custom-control-label::after {
    top: 0.4rem !important;
    width: 0.8rem !important;
    height: 0.8rem !important;
    background-size: 50% 50%;
    border-radius: 50%;
    border: solid 0.5px var(--cor-border-radio);
}

.margin-radio {
    margin-inline: 10px;
}

.custom-radio .custom-control-label::before {
    top: 0.4rem !important;
    width: 0.8rem !important;
    height: 0.8rem !important;
    background-color: var(--cor-text-menu)fff !important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none !important;
    background: var(--cor-principal) !important;
}

/************CheckBox****************/

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--cor-principal) !important
}

/***************Input-Form***********/

.div-wrapper {
    display: flex !important;
}

.div-wrapper-centralizado {
    display: grid;
    margin: 0px auto;
    width: 100%;
    align-items: center;
}

.div-wrapper label {
    margin: 0
}

/********************MENU******************/

.row-menu {
    width: 100%;
    text-align: right;
}

.navbar {
    width: 100%;
    display: block !important;
}

.label-titulo-consultas {
    font-size: 1.5rem;
    color: var(--cor-principal) !important;
    text-shadow: 1px 1px 1px #c2c2c26e;
}

.label-nome-pagina {
    font-size: 2rem;
    color: var(--cor-principal) !important;
    text-shadow: 1px 1px 1px #c2c2c26e;
}

.label-nome-detalhes {
    font-size: 3rem;
    color: var(--cor-principal) !important;
    text-shadow: 1px 1px 1px #c2c2c26e;
    margin: auto;
}

.parte-inferior {
    padding: 10px 0px;
}

.label-dashboard {
    text-align: right;
    font-size: smaller !important;
    color: var(--cor-text-secundario) !important;
}

.label-dashboard b {
    font-size: large;
}

.btn {
    background-color: var(--cor-principal) !important;
    border-color: var(--cor-principal) !important;
    color: var(--cor-text-menu);
    border-radius: 0.7rem;
    padding: 0px 10px;
}

.btn--invertido {
    background-color: whitesmoke !important;
    color: var(--cor-principal) !important;
}

.menu-hide {
    display: none;
}

/**************Tabela********************/

.correcaoRowTabela {
    width: 100%;
    margin: 0 15px !important;
}

.paddingSubTabela {
    padding: 0 8px 8px 8px;
}

.ReactTable .rt-expander:after {
    border-top: 7px solid var(--cor-expander-tabela);
}

.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
    border-top: 1px solid rgba(0, 0, 0, .05);
}

.rt-th .form-check-input {
    margin-left: -5px !important;
}

.rt-td .form-check-input {
    margin-left: -11px !important;
}

.ReactTable .rt-noData {
    background: none
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(84, 83, 83, 0.3);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(84, 83, 83, 0.3);
}

.ReactTable .rt-tr-group {
    text-align: center;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border-color: rgba(0, 0, 0, 0.15)
}

.rt-footer-total {
    text-align: center;
    height: 20px
}

.rt-footer-total>strong {
    position: absolute;
    left: 10px;
    font-size: 0.8rem;
}

.drop-down-toggle {
    display: inline-grid;
}

/********************************/

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: var(--cor-principal) !important;
    border-color: var(--cor-principal) !important;
}

.page-link:not(active) {
    color: var(--cor-text-secundario);
}

.page-link:hover {
    z-index: 2;
    color: var(--cor-primaria) !important;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

a:hover {
    /*mudar*/
    color: #0056b3;
    text-decoration: underline;
}

.underline {
    text-decoration: underline;
}

.page-link {
    position: relative;
    display: inline-block;
    padding: .5rem .75rem;
    /* margin-left: -1px; */
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

/***************MODAL*******************/

.modal-body .btn {
    margin-right: 10px;
}

.modal-title {
    width: 100%;
    text-align: center;
}

td.undefined.tabelaConsulta__celulaDados>.form-col.px-0.col>.form-group {
    margin-top: 0rem;
}

td.undefined.tabelaConsulta__celulaDados>.form-col.px-0.col>.form-group>.select.select-form.undefined.css-2b097c-container>.css-t6p3z9-control {
    height: 2rem !important;
}

.modal-grid-table {
    max-width: 92% !important;
}

.modal-medio {
    max-width: 75% !important;
}

.modal-map-body {
    padding: 0px !important;
}

.modal-map {
    max-width: 92% !important;
}

.modal-map2 {
    max-width: 100% !important;
}

.modal-content {
    border: none !important;
}

.modal-footer-novo {
    background-color: var(--light);
}

.modal-footer-titulo {
    text-align: center;
    background-color: var(--cor-principal);
    padding: 14px;
    color: white;
    font-weight: bold;
}

.modal-footer-novo div:not(:first-child) {
    padding: 8px !important;
}

/**********Nav-Consultas*************/

.navbar-consultas {
    padding-bottom: 0px !important
}

.nav-consultas {
    padding: 0px 4px;
    margin-top: 7px;
}

.nav a {
    display: block;
    padding: 0.78rem 0px 0rem 0px;
    margin: 0px 10px 0px 0px;
    text-decoration: none !important;
    color: var(--cor-primaria);
    display: block;
}

/***************CARDS****************/

.card-body {
    border-radius: 0 !important;
    position: relative;
}

.header-subfuncionalidade {
    color: white !important;
    border: none;
    min-height: 50px;
    padding: .75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    margin: 0 1px 1px 1px;
}

.icon-input {
    margin-left: 8px;
}

.card-collapse {
    border-radius: 0 !important;
    border-top: none !important
}

.card-collapse .card-body {
    box-shadow: none !important;
}

.preencherEspaco:after {
    content: '\200b';
}

.card-dashboard {
    background: rgba(242, 242, 242, 1) !important;
}

.row-dashboard-card {
    padding: 15px 0px !important;
}

/***************CONTEUDO*************/

.card-block {
    padding: 0;
}

.card {
    padding: 0;
    margin-bottom: 2px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.07) !important;
    border-radius: 15px;
}

.col-nopadding {
    padding: 0px !important;
}

.col-conteudo {
    padding: 15px !important
}

.padding-8px {
    padding: 8px
}

.margin-btn {
    margin-right: 10px;
}

.margin-btnConsulta {
    margin-top: 10px
}

.export-btnConsulta {
    margin-top: 10px;
    padding: 0.5rem;
    margin-bottom: 10px;
    border-radius: 32px;
    outline: none;
}

/*********************************/

#funcionalidade {
    border-radius: 0 !important;
}

#funcionalidade .list-group-item:first-child {
    background: var(--cor-principal);
    color: white !important;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    height: 50px;
}

.list-group-item {
    color: var(--cor-text-secundario) !important;
}

.list-group-item.active {
    background-color: rgba(36, 76, 59, 0.19) !important;
    border: none !important;
}

.card-subFuncionalidades {
    background: var(--cor-principal) !important;
    color: white !important;
}

.card-h5-header {
    display: inline-block;
    margin-top: 6px;
}

.cursor-ponteiro {
    cursor: pointer;
}

.nav-tabs .nav-item a {
    padding: 8px
}

.col-padding-card {
    padding: 1.25rem !important;
    padding-right: calc(1.25rem + 15px) !important;
    padding-left: calc(1.25rem + 15px) !important;
}

/********************SPINNER LOADER***********************/

.overlay {
    opacity: 0.8;
    background-color: #ccc;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 15000;
    display: flex
}

.loader {
    margin: auto;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--cor-principal);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/**********ALERT*************/

.alert {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 15001 !important;
}

.row-padding {
    padding: 0 15px !important;
}

/**********TEMP*************/

body,
.cor-body {
    background: var(--cor-body) !important;
}

.margin-top-padrao {
    margin-top: 10px;
}

.nav a:hover,
.nav a.active {
    color: white !important;
    background-color: var(--cor-principal) !important;
    border-radius: 3px 3px 0px 0px !important;
    border-color: var(--cor-principal);
}

.border-temp.nav a.active,
.border-temp.nav a:hover {
    color: white !important;
    background-color: var(--cor-principal) !important;
    border-radius: 3px 3px 0px 0px !important;
    border-color: var(--cor-principal);
}

.border-temp.nav .nav-item {
    margin-bottom: 0px !important;
}

.nav a {
    display: block;
    padding: 5px;
    margin: 0px 10px 0px 0px;
    text-decoration: none !important;
    color: var(--cor-primaria);
    display: block;
}

.border-temp {
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25) !important;
}

.nav.nav-tabs a:hover,
a.active {
    background: none;
}

.btnHeader,
.-btn {
    transition: 1s !important;
    color: var(--cor-text-verde) !important;
    background-color: var(--cor-principal) !important;
    background-image: url(../images/backbutton.png) !important;
    background-repeat: no-repeat !important;
    background-position: top right !important;
    float: right;
}

.iconAlert {
    transition: 1s !important;
    color: var(--cor-text-verde) !important;
    background-image: url(../Icons/Divergencia.svg) !important;
    background-repeat: no-repeat !important;
    width: 1rem;
    height: 1rem;
}

.iconObs {
    transition: 1s !important;
    color: var(--cor-text-verde) !important;
    background-image: url(../Icons/Observacao.svg) !important;
    background-repeat: no-repeat !important;
    width: 1rem;
    height: 1rem;
}

.iconLog {
    transition: 1s !important;
    color: var(--cor-text-verde) !important;
    background-image: url(../Icons/LogHistorico.svg) !important;
    background-repeat: no-repeat !important;
    width: 1rem;
    height: 1rem;
}

.fonte-negrito {
    font-weight: 700;
}

.ul-log {
    margin-left: -2rem;
}

.titulo-aula-log {
    font-weight: 700;
}

.logs-historico {
    margin-top: 1rem;
}

.icons-cadastro-oferta {
    display: flex;
    justify-self: center;
    align-items: center;
}

.btnEye,
.-btn {
    transition: 1s !important;
    color: var(--cor-text-verde) !important;
    background-color: var(--cor-principal) !important;
    background-image: url(../Icons/senha.svg) !important;
    background-repeat: no-repeat !important;
    background-position: top right !important;
    float: right;
}

.btnHeader:hover,
.-btn:hover {
    transition: 1s;
    color: var(--cor-body) !important;
    background-position: bottom left !important;
    box-shadow: 0 1px 4px 0 var(--cor-box-shadow) !important
}

.-btn {
    background-size: 380% !important;
    border: 1px solid var(--cor-principal) !important
}

.btn-secundario {
    background-color: var(--cor-text-secundario) !important;
    border-color: var(--cor-text-secundario) !important;
    color: white;
}

.btnHeader-danger {
    border-color: var(--cor-button-danger) !important;
    background-color: var(--cor-button-danger) !important;
    color: var(--cor-button-danger) !important;
}

.danger-cliente-bloqueado {
    border-color: var(--cor-button-danger) !important;
    background-color: var(--cor-button-danger) !important;
    color: var(--cor-body) !important;
}

.neogrid {
    border-color: var(--cor-principal-hover) !important;
    background-color: var(--cor-principal-hover) !important;
    color: var(--cor-body) !important;
}

.rt-thead .rt-tr {
    background-color: var(--cor-principal);
    color: white
}

.rt-thead.-filters .rt-tr {
    background-color: initial;
    color: white
}

.select-list {
    width: 100px !important;
    float: right;
}

.label-obrigatorio-list {
    margin-right: 25px;
    float: right;
}

.div-item-list {
    /* margin: 8px 0; */
    border: 1px solid var(--cor-principal);
    border-radius: 3px;
}

.icon-remover-list {
    float: right;
    padding: 2px;
    color: var(--cor-text-secundario);
}

.div-item-list::before {
    content: attr(data-indice);
    background-color: var(--cor-principal);
    color: white;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.025em;
    line-height: 38px;
    margin: 0 auto;
    padding: 8px;
    display: flex;
    align-items: center;
}

.navbar-brand {
    white-space: normal !important;
}

.label-item-relacionamento {
    font-size: 13px !important;
}

.legenda-item-lista {
    font-size: 1rem;
}

.legenda-item-lista span {
    float: right;
    font-size: 12px;
}

.fieldset-lista {
    border: 1px solid var(--cor-principal);
    width: 100% !important;
    background-color: transparent !important;
    border-radius: 3px;
    margin: 0px 0px 10px 0px !important;
    height: 500px;
    padding-top: 5px;
    overflow: unset;
}

.legend {
    margin: 0 8px;
    width: inherit !important;
}

/******** CAMPO-GENERICO**********/

.campo-generico {
    word-break: break-word;
}

.campo-click>span,
.celula-click {
    text-decoration: underline;
    cursor: pointer !important;
}

.campo-ellipsis,
.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.btnSelected {
    color: #FFFFFF !important;
    background-position: bottom left !important;
    opacity: 1 !important;
}

/******** MENU-MARCAÇÕES**********/

.mapa-modal {
    min-height: 94vh;
}

.titulo-header-modal {
    color: white;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
}

.mapa-modal-apenas {
    min-height: 86vh;
}

.menu-marcacoes-header {
    background-color: var(--cor-principal);
}

.border-bottom-lista {
    font-size: 14px;
    border-bottom: 1px solid var(--cor-border-bottom-form);
}

.border-bottom-lista-lg {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    border-bottom: 1px solid var(--cor-border-bottom-form);
}

.border-bottom-lista-lg .no-gutters.row {
    margin-top: 15px;
    height: 30px;
}

.tooltiptext {
    width: 70px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.menu-lista {
    overflow-y: auto;
    max-height: 88vh;
    height: 100%;
    word-break: break-word;
}

.float-none {
    float: none !important;
}

.item-button-menu-lista {
    background-size: 380% !important;
}

.no-margin {
    margin: 0px !important;
}

.marcador-mapa>div {
    transform: perspective(0px) rotateX(-20deg) rotateZ(45deg);
    color: white;
    padding: 6px 0px;
    text-align: center;
}

.icon-legenda>.marcador-mapa>div {
    padding: 3px 0px;
}

.icon-legenda>span {
    margin-left: 5px;
}

.mapa-legenda {
    position: absolute;
    bottom: 38px;
    right: 10px;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.29);
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
}

.icon-legenda {
    display: flex;
    margin-bottom: 12px;
    color: white;
    align-items: center;
}

.icon-legenda:last-child {
    margin-bottom: 0px;
}

.marcador-mapa {
    -webkit-transform: perspective(30px) rotateX(20deg) rotateZ(-45deg);
    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    width: 30px;
    height: 30px;
}

.marcador-codigo-funcionario {
    width: 40px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
}

.marcador-mapa-cliente {
    border-radius: 10% 10% 10% 10%;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    text-align: center;
    color: white;
    width: 30px;
    height: 30px;
}

.marcador-casa {
    background-image: url(../images/house-icon.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
}

.marcador-gps {
    background: var(--cor-marcador-gps);
}

.marcador-visita-negativada::after {
    border-color: var(--cor-marcador-visita-negativada) transparent transparent transparent;
}

.marcador-visita-negativada {
    background: var(--cor-marcador-visita-negativada);
}

.marcador-visita-inicial::after {
    border-color: var(--cor-marcador-visita-inicial) transparent transparent transparent;
}

.marcador-visita-inicial {
    background: var(--cor-marcador-visita-inicial);
}

.icon-leaflet-visita-final {
    z-index: 6000 !important;
}

.marcador-visita-final {
    background: var(--cor-marcador-visita-final);
}

.marcador-vendas {
    background: var(--cor-marcador-vendas);
}

.marcador-vendas-final {
    background: var(--cor-marcador-vendas-final);
}

.marcador-cliente {
    background: var(--cor-marcador-cliente);
}

.align-content-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
}

.header-acoes,
.align-content-ends {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.aprovacao-item-buttom-group {
    width: 148px;
    float: right;
}

.ver-titulos-cliente {
    text-decoration: underline !important;
    cursor: pointer !important;
    color: black !important;
}

/********DEFINIÇÃO ROTA***********/

.lista-mapa-rota {
    box-shadow: 0px 2px 20px -7px var(--cor-box-shadow) !important;
    margin: 20px 0px;
}

.item-lista-rota {
    /* border-radius: .25rem; */
    /* margin: 5px 0px !important; */
    border-bottom: 1px solid rgba(0, 0, 0, .080);
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    font-size: 0.7rem;
    background-color: white;
}

.lista-rota-header {
    /* box-shadow: 0 1px 4px 0 var(--cor-box-shadow) !important; */
    border-bottom: 1px solid rgba(0, 0, 0, .080);
    border-top: 1px solid rgba(0, 0, 0, .080);
    background-color: white;
    height: 60px;
    padding: 10px;
    font-weight: 500;
    line-height: 37px;
}

.lista-rota-header>span:first-child {
    font-weight: bold;
    line-height: 40px;
    font-size: 1.5rem;
    color: var(--cor-principal);
}

.lista-rota-header>span:nth-child(2) {
    float: right;
    width: 70%;
    line-height: 22px;
}

.mapa-rota {
    display: flex;
    height: calc(45vh + 60px);
    z-index: 0;
}

.border-between-list {
    border-right: 1px solid rgba(0, 0, 0, .125);
}

.lista-disponiveis-rota,
.lista-selecionados-rota {
    height: calc(100% - 61px);
}

.select-rota {
    width: 280px;
}

.select-replicar-rota {
    display: inline-block;
    width: calc(650px);
    margin: 0px 0 14px 0;
    padding-left: 0px !important;
}

.listas-drag-drop-rota {
    /* border-right: 1px solid rgba(0, 0, 0, .125); */
    height: calc(50vh + 60px);
}

/*********MODAL-EXIBICAO**********/

.tamanho-lista-unica,
.tamanho-lista-primaria,
.tamanho-lista-secundaria {
    min-height: 84vh;
}

.tamanho-lista-tabela>div {
    margin-left: 5px
}

.texto-upper {
    /* text-transform: uppercase; */
}

.marcacao-scroll-vertical {
    background-color: var(--cor-principal);
    border-radius: 5px;
}

.item-lista-exibicao {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    cursor: pointer;
    padding: 5px;
    margin: 5px 15px 5px 5px !important;
    font-size: 0.7rem;
    background-color: white;
}

.item-lista-secundaria-exibicao {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 5px;
    margin: 5px 15px 5px 5px !important;
    font-size: 0.7rem;
    background-color: white;
}

/* TODO REMOVER */

.item-lista-secundaria-exibicao p {
    margin: 0;
    word-break: break-word;
}

.ReactVirtualized__List {
    overflow: unset !important;
}

.item-lista-exibicao.active {
    border: 2px solid var(--cor-principal-hover);
}

.item-lista-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    box-sizing: border-box;
}

.item-lista-col-crud {
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    margin: 8px 10px 8px 10px !important;
}

.item-crud-meta {
    border: 1px solid rgba(0, 0, 0, .125);
    cursor: pointer;
    background-color: white
}

.item-crud-meta-header {
    padding: 0px 10px !important;
    box-shadow: 0 1px 4px 0 var(--cor-box-shadow) !important;
    background-color: white;
    height: 40px;
    font-size: 0.8rem;
    line-height: 40px;
    z-index: 1;
}

.item-crud-meta>[class*=col-]:not(.item-crud-meta-header) {
    padding: 5px 10px;
    font-size: 0.8rem;
    background-color: white;
}

.conteudo-modal-secundario .ReactTable .rt-tbody .rt-tr-group {
    border: none;
}

.header-modal-secundario {
    background-color: var(--cor-principal);
    padding: 0;
}

.headerModal_grupoCampo {
    color: white;
    text-align: center;
}

.header-modal-secundario>.row [class*=col-] {
    padding: 0;
    color: white;
}

.filtro-consulta-modal {
    transition: all 1s ease-out;
    background-color: white;
    -webkit-box-shadow: 0px 0px 6px -5px var(--cor-box-shadow);
    box-shadow: 0px 0px 6px -5px var(--cor-box-shadow);
}

.seta-consulta-modal {
    height: 20px;
    background-color: var(--cor-filtro-consulta);
    cursor: pointer;
}

.seta-consulta-modal>svg {
    transition: all 0.5s;
}

/* ITEM APROVAÇÃO */

.aprovacao-item-header {
    padding: 4px 10px !important;
    margin-bottom: 5px;
    box-shadow: 0px 2px 5px 0px var(--cor-box-shadow) !important;
    z-index: 1;
}

.aprovacao-item-header>p,
.aprovacao-item-conteudo>p {
    margin: 0px;
}

.aprovacao-item-conteudo {
    text-align: center;
    padding: 5px 0px;
}

.aprovacao-item-conteudo span {
    float: left;
}

.aprovacao-item-qtd-itens {
    display: flex;
}

.aprovacao-item-qtd-itens>div {
    flex: 1;
    text-align: center;
    color: white;
    padding: 9px;
    font-weight: bolder;
}

.aprovacao-item-footer {
    height: 38px;
    background-color: var(--cor-principal) !important;
    margin-top: 8px;
}

.aprovacao-item-footer-danger {
    background-color: var(--cor-qtd-itens-footer-danger) !important;
}

.aprovacao-item-valor {
    color: white;
    font-weight: bold;
    font-size: 1rem;
}

.grande-rede-promocao {
    padding: 0px 10px !important;
    background: var(--cor-principal);
    border-radius: 8px;
    color: white;
    font-size: 0.7rem !important;
}

.aprovacao-item-footer .custom-checkbox .custom-control-label::before {
    border-radius: .25rem;
    background: transparent;
    border: solid 2px white;
}

.aprovacao-item-footer .form-col {
    margin: auto;
}

.aprovacao-item-footer .div-wrapper {
    margin-left: 10px;
}

.aprovacao-item-footer .custom-control-input:checked~.custom-control-label::before {
    color: white;
    border-color: white;
    background-color: white;
}

.aprovacao-item-footer .custom-control-label {
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 22px;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.disabled-item-footer {
    background-color: var(--cor-disabled) !important;
}

.disabled-item-footer label {
    color: var(--cor-body) !important;
}

.small-text>label {
    font-size: x-small;
}

/* DESCONTOS AVINE */

.desconto-success {
    background-color: var(--cor-qtd-itens-ok) !important;
}

.desconto-success *,
.desconto-warning *,
.desconto-danger *,
.desconto-super-danger * {
    color: white !important;
}

.desconto-warning {
    background-color: var(--cor-qtd-itens-warning) !important;
    color: var(--cor-text-menu);
}

.desconto-danger {
    background-color: var(--cor-qtd-itens-footer-danger) !important;
    color: var(--cor-text-menu);
}

.desconto-super-danger {
    background-color: var(--cor-qtd-itens-danger) !important;
    color: var(--cor-text-menu);
}

.observacoes-tabela-form {
    background-color: var(--cor-expander-tabela);
    color: white;
    padding: 2px 10px;
}

.observacoes-tabela-form>b {
    font-weight: 600;
}

.opcaochecklist {
    padding: 16px;
}

.detalhe-opcaochecklist>span {
    background-color: var(--cor-principal);
    border: 1px;
    border-radius: 16px;
    width: 23px;
    height: 23px;
    display: inline-flex;
    float: right;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 1px 3px 0 var(--cor-box-shadow) !important;
    cursor: pointer;
}

.detalhe-opcaochecklist {
    line-height: 100%;
    color: var(--cor-text-secundario);
    margin: 0px;
}

.detalhe-opcaochecklist>b {
    font-size: 1rem;
    color: black;
}

.opcaochecklist> :nth-child(2) {
    font-size: 0.7rem;
    color: var(--cor-text-secundario);
    margin-bottom: 8px
}

.colunas-opcaochecklist {
    margin: 16px 0px;
}

.divisoria-colunas-opcaochecklist {
    border-right: 1px solid #ccc;
}

.opcaochecklist:last-child,
.opcaochecklist:nth-last-child(2) {
    padding: 16px 16px 0px 16px;
}

.opcaochecklist:first-child,
.opcaochecklist:nth-child(2) {
    padding: 0px 16px 16px 16px;
}

.itemchecklist-subjetivo {
    background-color: rgb(229, 229, 229);
    color: var(--cor-text-secundario);
    text-align: center;
    padding: 4px;
    margin: 0px;
    border: solid 1px #d0caca6b;
    word-break: break-all;
}

.itemchecklist-subjetivo.active {
    background-color: var(--cor-principal);
    overflow: auto;
    color: white;
}

.itemchecklist>svg {
    margin-right: 14px;
}

.itemchecklist {
    margin: 0px;
}

.itemporcentagem {
    font-size: 0.7rem;
    margin-left: 10px;
    line-height: 25px;
}

/** UPLOAD FORM **/

.img-file {
    width: 60px;
    height: 60px;
    color: var(--cor-principal);
    border-color: var(--cor-principal);
    border-width: 3px;
    border-radius: 8px;
}

.pdf-file {
    width: 60px;
    height: 60px;
    color: var(--cor-principal);
    border-color: var(--cor-principal);
    border-width: 3px;
    border-radius: 8px;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--cor-text-verde);
    border-style: dashed;
    color: var(--cor-text-secundario);
    outline: none;
    cursor: pointer;
    transition: border .24s ease-in-out
}

.list-drag-n-drop {
    overflow: auto !important;
}

/*
UniChristus
*/

.menu-modal-formal {
    box-shadow: 0px 2px 12px 0px var(--cor-box-shadow) !important;
    /* 0px 2px 10px 0px */
    padding: 15px 0px 0px 0px;
    margin-top: -1rem;
}

.menu-modal-formal .nav-tabs {
    border: none;
    margin-bottom: 0px;
}

.menu-modal-formal .nav a {
    font-weight: 700 !important;
}

.menu-modal-formal .nav a {
    font-size: 0.9rem;
    padding: 3px 8px 5px 8px;
    margin-top: 1px;
}

.menu-modal-formal .nav a:hover,
.menu-modal-formal .nav a.active {
    color: var(--cor-principal) !important;
    background-color: white !important;
    border: none;
    border-radius: 3px 3px 0px 0px !important;
    border-color: var(--cor-principal) !important;
    border-bottom: solid 3px;
}

.text-dark {
    color: var(--cor-primaria) !important;
}

.btn-transparente,
.btn-transparente:hover {
    background-color: transparent !important;
    color: var(--cor-principal) !important;
    font-weight: 700;
}

.table-default {
    width: 100%;
}

.table-default thead,
.table-default th {
    color: var(--cor-primaria);
}

.table-default tbody,
.table-default td {
    color: var(--cor-text-secundario);
    font-size: 1rem;
}

.table-default td,
.table-default th {
    padding: 0.5rem 0.25rem;
    text-align: left;
}

#root>div>div.no-padding.h-100.principal.container-fluid>div>div>div>div>div>div.col-sm-8.col-md-8>div>table>thead>tr>th:nth-child(1) {
    padding: 0.5rem 0.25rem;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    text-align: left;
}

#root>div>div.no-padding.h-100.principal.container-fluid>div>div>div>div>div>div.col-sm-8.col-md-8>div>table>tbody>tr:nth-child(1)>td:nth-child(1) {
    padding: 0.5rem 0.25rem;
    min-width: 250px;
    text-align: left;
}

/* alinhando sequencial da pagina sequencia da aula ao centro */

#root>div>div.no-padding.h-100.principal.container-fluid>div>div>div>div>div>div.col-sm-8.col-md-8>div>table>thead>tr>th:nth-child(3) {
    padding: 0.5rem 0.25rem;
    width: 110px;
    max-width: 110px;
    text-align: center;
}

#root>div>div.no-padding.h-100.principal.container-fluid>div>div>div>div>div>div.col-sm-8.col-md-8>div>table.table-default td:nth-child(3) {
    padding: 0.5rem 0.25rem;
    width: 60px;
    max-width: 60px;
    text-align: center;
}

.table-default td:first-child,
.table-default th:first-child {
    text-align: left !important;
}

.menuIcons {
    border-radius: 3px;
    padding: 3px;
    border: solid 0.07em var(--cor-menu-icons);
    border-width: 0.788px;
    font-size: 1.5rem;
    margin-right: 5px;
    cursor: pointer;
    color: white;
    background-color: var(--cor-principal);
}

.menuIconsReplica {
    border-radius: 3px;
    padding: 3px;
    border: solid 0.07em var(--cor-menu-icons);
    border-width: 0.788px;
    font-size: 1.5rem;
    margin-right: 7px;
    cursor: pointer;
    color: var(--cor-principal);
    background-color: white;
    border-color: var(--cor-principal);
    margin-top: 3rem !important;
}

.menuIcons:hover {
    box-shadow: 0px 0px 3px 0px var(--cor-box-shadow);
}

/* CARD  */

.card-crud {
    width: 92%;
    box-shadow: 0px 2px 12px 0px var(--cor-box-shadow) !important;
}

.header-legenda {
    padding: 0px 23px;
}

#pesquisar-wrap {
    max-width: 250px;
    display: inline-block;
    margin-right: 8px;
}

#pesquisar-wrap>input {
    background-image: url(../images/pesquisar.svg);
    background-position: right 10px bottom 50%;
    background-repeat: no-repeat;
    background-size: calc(1.5em + .75rem - 15px);
    padding-right: calc(1.5em + .75rem);
}

.text-header {
    color: var(--cor-principal) !important;
    text-align: left;
}

.table-default thead {
    font-size: 1.2rem;
}

.sem-dados {
    text-align: center;
    padding: 100px;
    color: var(--cor-principal);
    font-weight: bold;
}

.-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
}

.-pagination input,
.-pagination select {
    height: calc(1.5em + .4rem);
}

.-pagination .btn {
    display: block;
    width: 100%;
    height: 100%;
    /* padding: 6px; */
    transition: all .1s ease;
    outline-width: 0;
}

.-pagination .-btn[disabled] {
    opacity: .5;
    cursor: default;
}

.-pagination .-btn:not([disabled]):hover {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
}

.-pagination .-previous,
.-pagination .-next {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
}

.-pagination .-pageInfo {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
}

.-pagination .-pageJump {
    display: inline-block;
}

.-pagination .-pageJump input {
    width: 70px;
    text-align: center;
}

.-pagination .-pageSizeOptions {
    margin: 3px 10px;
}

.modal-confirm {
    max-width: 300px;
}

.texto-primario {
    color: var(--cor-principal);
}

.label-centralizado {
    width: 100%;
    text-align: center;
}

.modal-grid-table {
    max-width: 92% !important;
}

.modal-grid-locais {
    max-height: 45% !important;
    line-height: 0.5;
}

.modal-grid-temas {
    max-width: 85% !important;
    line-height: 0.5;
}

/*** CALENDARIO **/

.calendario-academico .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: var(--cor-dia-selecionado-calendario) !important;
    color: inherit;
}

.calendario-academico .-Day {
    padding: 0em;
}

.calendario-academico .dia-calendario-principal {
    padding: -0.5em;
}

.tipo-marcador-button {
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    color: white;
    border: hidden;
    border-radius: 0.7rem;
    margin-right: 0.5rem;
    white-space: pre-line;
    border-color: currentColor;
    font-weight: bold;
}

.tipo-info-button {
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    color: black;
    border: hidden;
    border-radius: 0.7rem;
    margin-right: 0.5rem;
    white-space: pre-line;
    border-color: currentColor;
    font-weight: bold;
}

.text-fim-semesestre {
    margin-left: 20%;
    font-weight: bold;
    color: #EB5757;
    font-size: xx-large;
}

.btn-padrao {
    width: 60%;
    height: 2rem;
}

.alinhamento-button {
    margin: 1rem;
    margin-left: 23%;
}

.texto-p {
    margin: 1rem;
    font-size: larger;
    text-align: center;
}

.texto-aviso {
    margin: 1rem;
    font-size: x-small;
    text-align: center;
    color: currentcolor;
}

.texto-data {
    margin: 1rem;
    font-size: medium;
    text-align: center;
    color: currentcolor;
    font-weight: bold;
}

.tipo-fim-button {
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    color: white;
    border: hidden;
    border-radius: 0.7rem;
    margin-right: 0.5rem;
    white-space: pre-line;
    border-color: currentColor;
    font-weight: bold;
    background-color: #EB5757;
}

.tipo-marcador-button:hover {
    border-radius: 0.7rem !important;
    color: rgb(229, 229, 229);
}

.tipo-marcador-button:active {
    border-radius: 0.7rem !important;
    color: rgb(229, 229, 229);
}

.tipos-marcadores>hr {
    border-bottom: 1px solid var(--cor-border-secundario);
}

.tipo-marcador {
    padding: 0.1rem 0.5rem;
    font-size: .7rem;
    color: white;
    border-radius: 0.7rem;
    margin-right: 0.5rem;
    white-space: nowrap;
}

.tipo-marcador:last-child {
    margin-right: 0px;
}

/****/

.calendario-academico .DayPicker {
    flex-direction: row;
    /* font-size: 2.5rem; */
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.calendario-academico .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

.calendario-academico .DayPicker-Months {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.calendario-academico .DayPicker-Month {
    min-height: calc(100% - 1em);
    min-width: calc(100% - 2em);
}

/* .info-calendario-padrao > fieldset {
    margin-bottom: 0.5rem;
} */

.info-calendario-padrao legend {
    font-weight: bold;
    font-size: 0.7rem;
    text-overflow: ellipsis;
}

.info-calendario-padrao p {
    font-size: 0.8rem;
    margin-bottom: .5rem;
}

.tipo-atividade-form-calendario {
    margin: 0rem 0rem;
    margin-top: 0rem !important;
}

.tipo-atividade-form-calendario .form-group {
    margin-bottom: 0rem;
    /* font-size: 0.9rem; */
}

/*  CALENDARIO PADRAO DE ATIVIDADES  */

.calendario-padrao {
    display: grid;
    width: 100%;
    grid-template-columns: 50px repeat(5, 1fr);
    grid-template-rows: 50px;
    grid-auto-rows: minmax(180px, auto);
}

.calendario-padrao--comDomingoESabado {
    display: grid;
    width: 100%;
    grid-template-columns: 50px repeat(7, 1fr);
    grid-template-rows: 50px;
    grid-auto-rows: minmax(150px, auto);
}

.calendario-padrao__mesesDaSemana {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendario-padrao-borda-inicial {
    border-right: 1px solid var(--cor-border-calendario);
}

.calendario-padrao-legenda-horario {
    width: 180px;
    /* transform-origin: calc(50%) calc(425%); */
    transform-origin: 92px 89px;
    transform: rotate(-90deg);
    text-align: center;
    position: absolute;
}

.calendario-padrao-legenda {
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
    text-overflow: ellipsis;
}

.calendario-padrao-conteudo {
    border-right: 1px solid var(--cor-border-calendario);
    border-bottom: 1px solid var(--cor-border-calendario);
}

.calendario-legenda-dias {
    border: 1px solid var(--cor-border-calendario);
    border-left: none;
}

.calendario-legenda-dias--bordaInferior {
    border-bottom: 1px solid var(--cor-border-calendario);
    border-left: none;
}

.calendario-legenda-dias--flecha {
    font-size: 0.8rem;
}

.calendario-padrao-selecionado {
    outline: #19385d solid 3px !important;
    z-index: 5;
}

/*************************************************/

/* MEDIA HEIGHT */

@media (max-height: 768px) {
    .tamanho-lista-primaria {
        min-height: 78vh;
    }

    .tamanho-lista-secundaria {
        min-height: 78vh;
        margin-top: 5px;
    }
}

/* MEDIA WIDTH */

@media (max-width: 1200px) {
    .sbsupervisor {
        font-size: 2.6rem;
    }

    .grid-layout {
        display: grid;
        width: 100%;
        /* //grid-template-columns: repeat(auto-fill, minmax(100%, 100%)); */
        grid-gap: 10px;
        grid-auto-rows: minmax(180px, auto);
        grid-auto-flow: dense;
        padding: 10px;
    }
}

@media (max-width: 992px) {
    .sbsupervisor {
        font-size: 2.2rem;
    }

    .div-endereco {
        font-size: 15px;
    }

    .button-replicar-rota {
        float: right;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .grafico-card::before {
        font-size: 1.0em;
        line-height: 34px;
        height: 45px
    }

    .-btn {
        background-size: 450% !important;
    }

    .header-acoes {
        justify-content: center;
    }
}

@media only screen and (max-width: 575px) {
    .tamanho-lista-unica {
        min-height: 80vh;
    }

    .tamanho-lista-tabela {
        min-height: 43vh;
    }

    .tamanho-lista-tabela>div {
        margin: 0px;
    }

    .logoSB {
        padding: 1.5rem 0.4rem 0.5rem 2.4rem;
    }

    .sidebar {
        width: 100%;
        min-height: 70px !important;
        box-shadow: 0 1px 4px 0 var(--cor-box-shadow)
    }

    .sidebar.active {
        width: 100%;
    }

    .principal {
        margin-left: 0px;
        margin-top: 75px;
    }

    .principal.active {
        transform: none;
    }

    .mapa-modal {
        min-height: 50vh;
    }

    .mapa-legenda {
        display: none;
    }

    .border-bottom-lista {
        font-size: 12px
    }

    .border-bottom-lista .btnHeader {
        font-size: .875rem;
    }

    .tamanho-lista-primaria {
        min-height: 43vh;
    }

    .tamanho-lista-secundaria {
        min-height: 43vh;
        margin-bottom: 8px;
        border-top: 8px solid #00000033;
    }

    .titulo-header-modal {
        font-size: 1rem;
        /* line-height: 0.5; */
    }

    .-btn {
        background-size: 500% !important;
    }

    .aprovacao-item-valor,
    .aprovacao-item-conteudo,
    .aprovacao-item-footer .custom-control-label {
        font-size: 0.7rem;
    }

    .aprovacao-item-buttom-group {
        float: none;
        margin: auto;
    }
}

@media only screen and (max-width: 423px) {
    html {
        font-size: 11px;
    }

    .DayPickerInput-Overlay {
        margin-left: -100px;
    }

    .-btn {
        background-size: 650% !important;
    }
}

@media only screen and (max-width: 360px) {
    .sbsupervisor {
        font-size: 2.0rem;
    }

    .div-endereco {
        font-size: 13.5px;
    }

    .-btn {
        background-size: 650% !important;
    }
}

/** ADICIONANDO + ORDER PARA O BOOSTRAP **/

.order-lg-13,
.order-md-13,
.order-xs-13,
.order-sm-13,
.order-13 {
    -webkit-order: 13;
    order: 13;
}

.order-lg-14,
.order-md-14,
.order-xs-14,
.order-sm-14,
.order-14 {
    -webkit-order: 14;
    order: 14;
}

.order-lg-15,
.order-md-15,
.order-xs-15,
.order-sm-15,
.order-15 {
    -webkit-order: 15;
    order: 15;
}

.order-lg-16,
.order-md-16,
.order-xs-16,
.order-sm-16,
.order-16 {
    -webkit-order: 16;
    order: 16;
}

.order-lg-17,
.order-md-17,
.order-xs-17,
.order-sm-17,
.order-17 {
    -webkit-order: 17;
    order: 17;
}

.order-lg-18,
.order-md-18,
.order-xs-18,
.order-sm-18,
.order-18 {
    -webkit-order: 18;
    order: 18;
}

.order-lg-19,
.order-md-19,
.order-xs-19,
.order-sm-19,
.order-19 {
    -webkit-order: 19;
    order: 19;
}

.order-lg-20,
.order-md-20,
.order-xs-20,
.order-sm-20,
.order-20 {
    -webkit-order: 20;
    order: 20;
}

.order-lg-21,
.order-md-21,
.order-xs-21,
.order-sm-21,
.order-21 {
    -webkit-order: 21;
    order: 21;
}

/* width */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #FFFFFF;
    border-radius: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: var(--cor-principal);
    border-radius: 5px;
}

.grid-form {
    width: 80%;
}

/* Handle on hover
::-webkit-scrollbar-thumb:hover {
    background: var(--cor-marcador-visita-inicial);
}
*/

/* Carousel */
.carousel-indicators {
    position: absolute;
    right: auto;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 10%;
    margin-left: 20%;
    list-style: none;
    margin-bottom: 4.5rem;
}

.carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
}

.carousel-caption h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
}

.carousel-caption p {
    font-size: 1rem;
    text-align: left;
}

/* Noticias */

.noticia__titulo {
    color: var(--cor-principal);
    font-size: 2em;
}

.noticia__dataHora {
    color: var(--cor-text-secundario);
}

/*Cores*/

.azul-principal {
    color: var(--cor-principal);
}

/* Alturas */

.h-33 {
    height: 33% !important;
}

/* Larguras */

.w-33 {
    width: 33% !important;
}

.clicavel {
    text-decoration: none;
}

.clicavel:hover {
    text-decoration: underline;
}

.ofertaPeriodo__card {
    border-radius: 30px;
}

.ofertaPeriodo__card--titulo {
    font-size: 4rem;
    text-overflow: ellipsis !important;
}

.CardCalendarios__card {
    border-radius: 30px;
}

.btn-excluir {
    width: 95%;
    margin: 5% 5% 0;
    margin-bottom: 0%;
}

.butom-pesquisa {
    height: 2rem;
    width: 5rem;
}

.CardCalendarios__card--titulo {
    font-size: 2rem;
    text-align: center;
}

.semenstredocalendario {
    margin: auto;
}

.card-calendario {
    width: 80%;
}

/* Draft */

.draft-body {
    border: solid 0.07em;
    border-radius: 10px;
}

.draft-editor {
    border-style: none;
    border: 5px;
}

.button-novo {
    margin-top: 1rem;
    font-size: 1.25rem;
    height: 2rem;
    width: 10rem;
}

.draft-tools {
    background-color: var(--cor-principal);
    border-style: hidden !important;
    border-radius: inherit;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}

.titulo-calendario-modal {
    font-size: 2rem;
    color: var(--cor-principal);
}

.draft-tools .rdw-option-wrapper {
    border: 4px solid #F1F1F1;
    min-width: 25px;
    height: 20px;
    border-radius: 3px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

.blockText {
    color: #212121;
}

.blockText .rdw-option-active {
    font-weight: bold;
}

/* Calendário */

.semestreComTemas__semestre {
    width: 56px;
    height: 56px;
    border: 2px solid;
    border-radius: 50%;
    color: #BDBDBD;
}

.semestreComTemas__semestre-gerado {
    width: 56px;
    height: 56px;
    border: 2px solid;
    border-radius: 50%;
    color: var(--cor-principal);
}

.semestreComTemas__semestre-alocado {
    width: 56px;
    height: 56px;
    border: 2px solid;
    border-radius: 50%;
    color: #6FCF97;
}

.semestreComTemas__linha {
    border: 1px solid;
    height: 1px;
    width: 56px;
    color: #BDBDBD;
}

.semestreComTemas__linha--gerado {
    border: 1px solid;
    height: 1px;
    width: 56px;
    color: var(--cor-principal);
}

.semestreComTemas__linha--completo {
    border: 1px solid;
    height: 1px;
    width: 56px;
    color: #6FCF97;
}

.edite-button {
    margin-left: 83%;
    margin-bottom: -13%;
}

.semestreComTemas__tema {
    height: auto;
    width: 320px;
    padding: 20px 0px;
    border: 2px solid;
    color: var(--cor-principal);
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
}

.semestreComTemas__tema--completo {
    height: 56px;
    border: 2px solid;
    color: #6FCF97;
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
}

.ComTemas__tema {
    height: 7rem;
    border: 2px solid;
    color: var(--cor-principal);
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
}

.semestreComTemas__tema:hover {
    background-color: var(--cor-principal);
    color: whitesmoke;
    text-decoration: none;
}

.semestreComTemas__tema--completo {
    color: green;
}

.semestreComTemas__tema--completo:hover {
    background-color: #6FCF97;
}

.semestreComTemas__temaTexto:hover {
    text-decoration: none;
}

.semestreComTemasOfertas__temaLink:hover {
    text-decoration: none;
}

.semestreComTemasOfertas__tema {
    height: auto;
    border: 2px solid;
    color: #BDBDBD;
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
    text-align: center;
}

.semestreComTemasOfertas__tema:hover {
    background-color: #BDBDBD;
    color: whitesmoke;
    text-decoration: none;
}

.semestreComTemasOfertas__tema--completo {
    height: auto;
    border: 2px solid;
    color: #6FCF97;
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
    text-align: center;
}

.semestreComTemasOfertas__tema--completo:hover {
    background-color: #6FCF97;
    color: whitesmoke;
}

.semestreComTemasOfertas__tema--gerado {
    height: auto;
    border: 2px solid;
    color: var(--cor-principal);
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
    text-align: center;
}

.semestreComTemasOfertas__tema--gerado:hover {
    background-color: var(--cor-principal);
    color: whitesmoke;
}

.butom-semsestres {
    margin: 1rem;
}

.ComTemas__tema {
    height: 7rem;
    border: 2px solid;
    color: var(--cor-principal);
    border-radius: 10px;
    transition: 0.5s;
    text-decoration: none;
}

.horarioCalendario__contextMenu {
    background-color: ghostwhite;
    padding: 6px 4px;
    display: grid;
    border-radius: 10px;
}

.zindex-tooltip {
    z-index: 1000;
}

.css-xb97g8 {
    color: #FFFFFF;
}

.css-12jo7m5 {
    color: #FFFFFF !important;
    border-radius: 12px !important;
}

.css-1rhbuit-multiValue {
    background-color: #19385D !important;
    border-radius: 12px !important;
}

.modalVisualizarHorarioAula {
    color: grey;
}

.modalVisualizardetalhes {
    max-width: 80% !important;
    max-height: 40% !important;
}

.modalVisualizarHorarioAula__label {
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 5px;
    text-overflow: ellipsis;
}

.modalVisualizarHorarioAula__wrapperTexto {
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    border-radius: 4px;
    padding: 4px 7px;
    border-radius: 10px;
    border-style: ridge;
    padding-top: 10px;
    padding: inherit;
    text-overflow: ellipsis !important;
}

.blocoPadraoComAula {
    display: grid !important;
    font-size: 0.6rem;
    font-weight: 600;
    text-overflow: ellipsis;
    border-bottom: 0.25px;
    border-block-end-style: groove;
}

.blocoPadraoComAula__texto--comOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.blocoPadraoComAula__texto--leve {
    font-weight: 400;
}

input.undefined.form-control {
    /* text-transform: uppercase; */
    text-overflow: ellipsis !important;
}

.DayPickerInput-OverlayWrapper {
    top: -200px !important;
    position: absolute !important;
}

.tipos-marcadores.col {
    flex-basis: auto !important;
}

.tipos-marcadores-salve.col {
    flex-basis: auto !important;
    margin-top: 1.5rem !important;
}

.col-9.col-sm-10.col-md-10.col-lg-11.col-xl-11 {
    border: inset !important;
    border-top-style: outset !important;
    border-left-style: outset !important;
    border-right: none !important;
    border-bottom: transparent !important;
}

.col-3.col-sm-2.col-md-2.col-lg-1.col-xl-1 {
    margin-top: 0rem !important;
    border: inset !important;
    border-left: none !important;
    border-top: outset !important;
    border-bottom: none !important;
}

.modalConsulta {
    border-radius: inherit;
    padding: 0;
}

.modalAlunosGrupó {
    border-radius: inherit;
    padding: 0;
}

.modalConsulta__menu {
    background-color: var(--cor-principal);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 1.4rem 1.2rem 0.7rem !important;
}

.modalConsulta__calendarios {
    overflow: auto;
    height: 80vh;
    max-height: 800px;
    width: 100%;
}

.tabelaConsulta {
    width: 100%;
    font-size: 0.9rem;
}

.tabelaGrupo {
    max-width: 100%;
    font-size: 0.9rem;
    max-height: 92%;
}

.tabelaConsulta__celulaCabecalho {
    background-color: var(--cor-principal);
    border: 1px solid var(--cor-principal);
    color: white;
    padding: 0.2rem 0.4rem;
    text-align: center;
}

.tabelaConsulta__celulaDados {
    border: 1px solid var(--cor-principal);
    color: var(--cor-text-secundario);
    font-weight: 600;
    padding: 0.2rem 0.4rem;
    text-align: center;
}

.form-group {
    margin-top: 1rem !important;
}

.new-form-group.form-group {
    margin-top: 0;
}

.ml-auto {
    margin-top: 10px !important;
}

.nome_modal {
    font-size: 51px;
    color: var(--cor-principal) !important;
    text-shadow: 1px 1px 1px #c2c2c26e;
    color: var(--cor-principal);
}

.nome_modal_detalhe {
    font-size: 51px;
    color: var(--cor-principal) !important;
    text-shadow: 1px 1px 1px #c2c2c26e;
    margin-left: 40% !important;
    color: var(--cor-principal);
}

.float-center {
    margin-left: 37%;
}

.modal-dialog-tabels {
    margin: 1.75rem auto !important;
    max-width: 600px !important;
}

media.modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
}

.card-header:first-child {
    border-radius: 15px !important;
}

.ml-5 {
    margin-left: 1rem !important;
}

.local-el {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.btn-delete,
.btn-delete:hover {
    background-color: var(--cor-principal) !important;
    color: white !important;
    font-weight: 700;
}

.col-sm-6 {
    max-width: 100% !important;
}

.separacaoOfertas {
    border-bottom: outset;
}

.hotrarioAulas {
    margin-right: 5rem !important;
}

.spaVisual {
    text-overflow: ellipsis;
}

.btn-limpar-filtro {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1rem;
}

.popover {
    max-width: 100%;
}

.filtro-tabela {
    height: calc(0.7em + .75rem);
    border-radius: 0%;
    text-overflow: ellipsis !important;
    text-align: center;
}

.input-tabela {
    border: none;
    border-width: 0;
    border-radius: 0%;
    box-shadow: none;
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: .75rem;
}

.input-tabela.is-invalid {
    border: none;
    border-width: 0;
    border-radius: 0%;
    box-shadow: none;
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: .75rem;
}

.input-tabela.is-valid {
    border: none;
    border-width: 0;
    border-radius: 0%;
    box-shadow: none;
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: .75rem;
}

.tabela_colunm_tema,
.tabela_colunm_semestre {
    width: 7%;
}

.tabela_colunm_tipo-de-atividade {
    width: 20%;
}

.tabela_colunm_titulo-da-aula {
    width: 60%;
}

td.tabela_colunm_titulo-da-aula {
    max-width: 50rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#tabela-carga-tipo-atividade tr>th,
#tabela-carga-tipo-atividade tr>td {
    vertical-align: middle;
}

#tabela-carga-tipo-atividade tr>th:first-child,
#tabela-carga-tipo-atividade tr>td:first-child {
    width: 15rem;
}

#tabela-carga-tipo-atividade>thead {
    background: var(--cor-principal);
    color: white;
    text-align: center;
}

#tabela-carga-tipo-atividade>tbody {
    text-align: center;
    padding: 0.5rem;
}

#tabela-trocar-aula tr>th,
#tabela-trocar-aula tr>td {
    vertical-align: middle;
}

#tabela-trocar-aula>tbody>tr>td:nth-child(3) {
    width: 400px;
}

#tabela-trocar-aula>thead>tr>th:last-child {
    width: 85px;
}

#tabela-trocar-aula>thead {
    background: var(--cor-principal);
    color: white;
    text-align: center;
}

#tabela-trocar-aula>tbody {
    text-align: center;
    padding: 0.5rem;
}

#tabela-divergencia-troca-aula>thead {
    background: var(--cor-principal);
    color: white;
    text-align: center;
}

#modal-lista-alunos-grupo>div {
    width: 1100px;
    position: relative;
    left: -15%;
}

/* TABELA MENSAGENS */
.tabela_colunm_semestre {
    width: 7%;
}

.tabela_colunm_tema {
    width: 15%;
}

.tabela_colunm_publico {
    width: 10%;
}

.tabela_colunm_titulo {
    width: 15%;
}

/* Consultas */
.align-content-buttons_consulta {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: end;
    margin-bottom: 0rem;
    margin: 10px;
}

.contagem-caracteres {
    margin-left: auto;
    margin-right: 0.5rem;
    margin-top: -1rem;
}

.modal-dialog.custom-modal-size-publicado {
    max-width: 38%;
    width: 70%;
}

.text-modal-publicado.row {
    justify-content: center;
}

.buttons-modal-publicado.row {
    margin-top: 1rem;
    display: flex;
    place-content: space-evenly;
}

/* Dashboard */
.rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.button-filter-graph-dashboard {
    --text-opacity: 1;
    color: #fff;
    background-color: #19385D;
    font-size: 0.875rem;
    width: auto;
    height: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-width: 1px;
    border-color: #19385D80;
}

.button-filter-indigo-graph-dashboard {
    --text-opacity: 1;
    color: #19385D;
    background-color: white;
    width: auto;
    height: 100%;
    font-size: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-width: 1px;
    border-color: #19385D80;
}


.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.div-button-group-filter-dashboard {
    width: auto;
    height: 2.5rem;
    border-radius: 0.75rem;
    border-color: #19385D;
}

.h-100.undefined.card-body {
    overflow: scroll;
}

.col-conteudo.semana-padrao.col-sm-12.col-md-12 {
    width: 0;
}

.slider {
    margin: 0 1px;
    overflow: "hidden";
    padding: 1rem 0;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.careousel-div {
    width: 108rem;
}

.careousel-middle-div {
    width: 80rem;
}

.careousel-small-div {
    width: 70rem;
}

.tab-navbar-noticias {
    margin-top: 2rem;
}

.tab-navbar-noticias .nav-tabs {
    border: none;
    margin-bottom: 0px;
}

.tab-navbar-noticias .nav a {
    font-weight: 700 !important;
}

.tab-navbar-noticias .nav a {
    font-size: 0.9rem;
    padding: 3px 8px 5px 8px;
    margin-top: 1px;
}

.tab-navbar-noticias .nav a:hover,
.tab-navbar-noticias .nav a.active {
    color: var(--cor-principal) !important;
    background-color: white !important;
    border: none;
    border-radius: 3px 3px 0px 0px !important;
    border-color: var(--cor-principal) !important;
    border-bottom: solid 3px;
}

.carousel-caption {
    background-color: #19385D;
    padding: 3rem;
    margin-bottom: 3rem;
    /* height: 15rem; */
    /* padding-left: 9rem; */
    border-radius: 0px 16px 16px 0px;
    gap: 12px;
}

.TextCont p {
    color: white !important;
}

.TextCont h3 {
    color: white !important;
}

.TextCont {
    padding: 0% !important;
    position: relative;
    height: 50%;
    right: 11%;
    width: 70%;
    font-size: 25px;
    flex-wrap: wrap;
}

.css-16mg828-control {
    height: 2rem;
}

.formatted-text {
    white-space: pre-line;
}

.div-de-teste {
    overflow: auto;
}

.card-noticias {
    margin-bottom: 2rem;
}

.informacoes-semestre-semana-padrao {
    position: absolute;
    left: 0;
    margin-left: 1rem;
    font-weight: 600;
}

/* --------------------------------------- PDF SEMANA PADRÃO --------------------------------------- */

.pdf-legenda-dias {
    display: flex;
    width: 100%;
    /* justify-content: space-evenly; */
}

.pdf-semana-horario,
.pdf-titulo-bloco {
    display: flex;
}

.pdf-titulo-bloco {
    display: flex;
    justify-content: space-around;
    font-size: 11px;
}

.pdf-calendario-horario-aula {
    width: 4rem;
    min-height: 12.07rem;
}

.pdf-calendario-padrao-conteudo {
    border-right: 1px solid var(--cor-border-calendario);
    border-bottom: 1px solid var(--cor-border-calendario);
    width: 14rem;
}

.pdf-local-el {
    font-size: 0.6rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.pdf-align-content-middle {
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
    min-height: 10rem;
}

.pdf-blocoPadraoComAula {
    display: grid !important;
    font-size: 0.6rem;
    font-weight: 600;
    text-overflow: ellipsis;
    border-bottom: 0.25px;
    /* border-block-end-style: groove; */
}

.pdf-local-grupo.no-gutters.row {
    align-items: center;
    display: flex;
    overflow-wrap: anywhere;
}

.pdf-local {
    display: flex;
}

.pdf-blocoPadraoComAula__texto--comOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 8px;
}

.pdf-bloco-final {
    font-size: 0.7rem;
    margin-top: 0.5rem;
}

.pdf-align-content-middle {
    display: grid;
}

.pdf-local.col {
    font-size: 9px;
    padding: 0;
    width: 4rem;
    display: inline-block;
}

.pdf-align-content-middle-calendario.calendario-padrao-legenda.texto-secundario.calendario-legenda-dias--bordaInferior {
    width: 14rem;
}

.pdf-espaçamento-dias {
    width: 4rem;
}

.pdf-align-content-middle-calendario.calendario-padrao-legenda.texto-secundario.calendario-legenda-dias--bordaInferior {
    width: 14rem;
}

.pdf-align-content-middle-calendario {
    display: grid;
}

.pdf-align-content-middle-calendario {
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
}

.pdf-calendario-padrao-conteudo {
    border-right: 1px solid var(--cor-border-calendario);
    border-bottom: 1px solid var(--cor-border-calendario);
}

.remover-padding.col {
    padding: 0;
}

.pdf-calendario-padrao-legenda {
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
    text-overflow: ellipsis;
    width: 9rem;
}

.pdf-local-grupo.row {
    margin: 0;
    width: 9rem;
    display: flex;
    place-items: center;
}

.pdf-grupos.col {
    padding: 0;
    display: inline-block;
    width: 4rem;
    overflow-wrap: anywhere;
}

.pdf-text-header {
    font-size: 0.8rem;
    font-weight: 600;
}

.pdf-infos-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
}

.pdf-semana-padrao-file {
    width: 12rem;
}

.pdf-header-principal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
}

span.pdf-text-semana-padrao {
    font-weight: 600;
    color: var(--cor-principal);
}

.icon-pdf-semana-padrao,
.icon-pdf-semana-padrao-bloqueado {
    position: absolute;
    right: 2rem;
}

.icon-pdf-semana-padrao-bloqueado {
    cursor: not-allowed;
}

.icon-pdf-semana-padrao {
    cursor: pointer;
}

.pdf-footer,
.pdf-frase-final {
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 600;
}

.pdf-footer {
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.pdf-datas-footer {
    display: grid;
    justify-items: center;
}

.pdf-color-email {
    color: #2067bb;
}

.pdf-frase-final {
    justify-content: center;
    color: #f32245;
}

.modal-dialog.modal-atualizar-local {
    padding-top: 17rem;
    width: 20rem;
}

.flex-column.nome-usuario-submenu {
    display: flex;
    place-self: center;
    color: white;
}

.btn-limpar-filtro-agenda {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
}

button.mx-3.py-2.pesquisar-agenda.btn.btn-secondary {
    width: 100%;
}

.sequencia-aula>.card>.h-100.undefined.card-body {
    overflow: unset !important;
}

.tela-agenda>.css-16mg828-control,
.tela-agenda>.css-t6p3z9-control,
.height-form>.css-t6p3z9-control,
.height-form>.css-4f9qnl-control,
.height-form>.css-16mg828-control {
    height: calc(1.5em + 0.75rem);
}

.overflow-professor {
    overflow: unset !important;
    /* overflow-y: auto !important; */
}

td.coluna-fixa {
    background-color: #e9ecef;
}

.tb-professor-grupo {
    width: 169rem;
    /* overflow: auto; */
}

.div-de-teste {
    overflow: auto;
    height: 38rem;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor {
    align-self: center;
    height: 4rem;
    display: flex !important;
    align-items: center;
    background-color: #e9ecef;
}

table.tabelaConsulta.header-professor {
    position: sticky;
    top: 0px;
    z-index: 1;
}

thead.header-professor {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* .novo-teste {
    overflow: auto;
} */

.td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group {
    margin-top: 1rem !important;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group>.select.select-form.undefined.css-2b097c-container>.css-4f9qnl-control {
    height: 3.7rem;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group>.select.select-form.undefined.css-2b097c-container>.css-7zbhwn-control {
    height: 3.7rem;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group>.select.select-form.undefined.css-2b097c-container>.css-16mg828-control {
    height: 3.7rem;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group>.select.select-form.undefined.css-2b097c-container>.css-t6p3z9-control {
    height: 3.7rem !important;
}

td.undefined.tabelaConsulta__celulaDados.tabela-professor>.form-col.px-0.col>.form-group {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.semestre-turma {
    height: 4rem;
}

#modal-professor-grupo>div {
    width: 97rem;
    position: relative;
    left: -42%;
}

.titulo-modal-critica.row,
.titulo-modal-conteudo-prova {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    display: grid;
    justify-items: center;
}

.titulo-modal-conteudo-prova {
    width: 50rem;
}

.modal-dialog.modal-critica,
.titulo-modal-conteudo-prova {
    left: -1px;
    top: 150px;
}

h4.alerta-modal-critica,
.titulo-modal-conteudo-prova {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: 700;
}

.modal-conteudo>.modal-content {
    width: 70rem;
    left: -44%;
}

td.array_professor.tabelaConsulta__celulaDados {
    background-color: #e9ecef;
    align-content: center;
}

h1.conteudo-professor-modal {
    font-size: 1rem;
    border: none;
    border-width: 0;
    border-radius: 0%;
    box-shadow: none;
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: .75rem;
    color: var(--cor-text-secundario);
}

td.undefined.tabelaConsulta__celulaDados {
    background-color: #e9ecef;
    align-content: center;
}

.modal-conteudo>.modal-content>.modal-body>.div-de-teste {
    height: 45rem;
    justify-content: center;
    display: grid;
}

.modal-conteudo>.modal-content>.modal-body {
    text-align: -webkit-center;
}

.pop-up-conteudo {
    position: absolute;
    background: white;
    margin-left: 11rem;
    margin-top: 4rem;
    border-radius: 1rem;
    padding: 0px;
    width: 170px;
    height: 53px;
    /* display: flex; */
    z-index: 5;
    text-align: -webkit-center;
}

.custom-modal .modal-dialog {
    max-width: 65%;
    width: 90%;
}

.custom-modal>.modal-content {
    height: auto;
}

tr.modal-descricao-conteudo {
    /* border: 1px solid; */
}

.modal-conteudo-edit {
    color: var(--cor-text-secundario);
}

.modal-editar-conteudo.modal-body {
    padding: 2rem;
}

.mt-2.linha-conteudo-prova.row {
    /* border-bottom: 1px solid var(--cor-text-secundario); */
}

.filtro-modal-conteudo.row {
    justify-content: center;
    margin-right: 0;
    place-items: center;
    margin-bottom: 1.5rem;
    margin-left: 0;
    width: 30rem;
}

.add-aula.col-md-2 {
    text-align-last: end;
}

.btn-fechar-modal {
    float: right;
}