.card-dnd-title {
    height: 50px
}

.card-dnd-aulas {
    overflow-y: scroll;
    height: 100vh;
    padding-right: 0px !important;
}

.card-dnd-aulas>.bloco-dnd-aula {
    padding: 10px;
    border-radius: 10px;
    margin: 10px
}

.card-dnd-aulas>.bloco-dnd-aula>.nome {
    font-size: .75rem;
}

.card-dnd-aulas>.bloco-dnd-aula>.atividade {
    font-size: .65rem;
    font-weight: 600;
}