.col-md-6.modal-configuracao {
    align-content: center;
}

.modal-configuracao-tab-2 {
    display: -webkit-inline-box;
}

.modal-configuracao-tab-2>.form-col.undefined.col-6.col-sm-3.col-md-3.col-lg-3.col-xl-3 {
    padding: 0;
    margin-left: 0.5rem;
}

.botoes-modal-configuracao.col-md-2 {
    text-align: center;
    align-content: center;
    margin-bottom: 1rem;
}